<template>
  <b-card>
    <b-card-header
      class="header-df"
    >
      <b-card-title>Sección I</b-card-title>
      <b-card-sub-title>Filiación e identificación personal</b-card-sub-title>
      <feather-icon
        icon="InfoIcon"
        size="36"
        class="mr-25"
      />
    </b-card-header>
    <b-card-body
      class="pl-0 pr-0"
    >
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>Datos Generales</span>
          </template>

          <b-form
            class="pb-2 pl-0 pr-0"
          >
            <b-media class="mb-0">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src="`data:image/jpeg;base64,${dataTrabajador.foto}`"
                  size="125px"
                  rounded
                />
              </template>

              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Documento de Identidad"
                    label-for="nroDoc"
                  >
                    <b-input-group class="input-group-merge">
                      <v-select
                        id="frmTipoDoc"
                        v-model="tipoDocSel"
                        label="abreviatura"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="tiposDocument"
                      />
                      <b-form-input
                        id="search-nro-doc"
                        v-model="dataTrabajador.documento"
                        style="padding: 0.438rem 1rem;"
                      />
                      <b-button
                        variant="info"
                        @click="searchReniec"
                      >
                        <feather-icon icon="SearchIcon" />
                      </b-button>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Nombres"
                    label-for="nombre"
                  >
                    <b-form-input
                      id="nombre"
                      v-model="dataTrabajador.nombre"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Apellido Paterno"
                    label-for="apellido_paterno"
                  >
                    <b-form-input
                      id="apellido_paterno"
                      v-model="dataTrabajador.apellidoPaterno"
                    />
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Apellido Materno"
                    label-for="apellido_materno"
                  >
                    <b-form-input
                      id="apellido_materno"
                      v-model="dataTrabajador.apellidoMaterno"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-media>

            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Sexo"
                  label-for="sexo"
                >
                  <v-select
                    v-model="dataTrabajador.sexo"
                    label="title"
                    :reduce="m => m.title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sexos"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Tipo de Sangre"
                  label-for="tipo_sangre"
                >
                  <v-select
                    v-model="dataTrabajador.tipoSangre"
                    label="title"
                    :reduce="m => m.title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tiposSangre"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Estado Civil"
                  label-for="estado_civil"
                >
                  <v-select
                    v-model="dataTrabajador.estadoCivil"
                    label="nombre"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="estadosCiviles"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Idioma"
                  label-for="idioma"
                >
                  <v-select
                    v-model="dataTrabajador.idioma"
                    label="title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="idiomas"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Teléfono Fijo"
                  label-for="telefonoFijo"
                >
                  <b-form-input
                    id="telefonoFijo"
                    v-model="dataTrabajador.telefonoFijo"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Celular"
                  label-for="celularNro1"
                >
                  <b-form-input
                    id="celularNro1"
                    v-model="dataTrabajador.celular"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Celular"
                  label-for="celularNro2"
                >
                  <b-form-input
                    id="celularNro2"
                    v-model="dataTrabajador.celularNro2"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Correo Electrónico"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="dataTrabajador.correo"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <h6
              class="text-info"
            >
              <feather-icon icon="CalendarIcon" />
              <span class="align-middle ml-25">Datos Nacimiento</span>
            </h6>

            <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Fecha Nacimiento"
                  label-for="fecha_nac"
                >
                  <flat-pickr
                    v-model="dataTrabajador.fechaNacimiento"
                    class="form-control"
                    :config="configDP"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group
                  label="País Nacimiento"
                  label-for="pais_nac"
                >
                  <v-select
                    v-model="paisNacSel"
                    label="title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paises"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Departamento Nacimiento"
                  label-for="dpto_nac"
                >
                  <v-select
                    v-model="dptoNacSel"
                    label="descripcion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dptosNac"
                    @input="getProvs('NAC', dptoNacSel.codigoDepartamento)"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Provincia Nacimiento"
                  label-for="prov_nac"
                >
                  <v-select
                    v-model="provNacSel"
                    label="descripcion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="provsNac"
                    @input="getDists('NAC', dptoNacSel.codigoDepartamento, provNacSel.codigoProvincia)"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Distrito Nacimiento"
                  label-for="dist_nac"
                >
                  <v-select
                    v-model="distNacSel"
                    label="descripcion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="distsNac"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <h6
              class="text-info"
            >
              <feather-icon icon="MapIcon" />
              <span class="align-middle ml-25">Domicilio Reniec</span>
            </h6>

            <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Ubigeo"
                  label-for="ubigeoReniec"
                >
                  <b-form-input
                    id="ubigeoReniec"
                    v-model="dataTrabajador.ubigeoReniec"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Dirección"
                  label-for="direccionReniec"
                >
                  <b-form-input
                    id="direccionReniec"
                    v-model="dataTrabajador.direccionReniec"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <h6
              class="text-info"
            >
              <feather-icon icon="MapPinIcon" />
              <span class="align-middle ml-25">Domicilio Actual</span>
            </h6>

            <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

            <b-row>
              <!-- <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="País Nacimiento"
                  label-for="pais_nac"
                >
                  <v-select
                    v-model="paisNacSel"
                    label="title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="paises"
                  />
                </b-form-group>
              </b-col> -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Departamento"
                  label-for="dpto_res"
                >
                  <v-select
                    v-model="dptoResSel"
                    label="descripcion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dptosRes"
                    @input="getProvs('RES', dptoResSel.codigoDepartamento)"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Provincia"
                  label-for="prov_res"
                >
                  <v-select
                    v-model="provResSel"
                    label="descripcion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="provsRes"
                    @input="getDists('RES', dptoResSel.codigoDepartamento, provResSel.codigoProvincia)"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Distrito"
                  label-for="dist_res"
                >
                  <v-select
                    v-model="distResSel"
                    label="descripcion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="distsRes"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                md="8"
              >
                <b-form-group
                  label="Dirección Domicilio Actual"
                  label-for="dirActualNombre"
                >
                  <b-form-input
                    id="dirActualNombre"
                    v-model="dataTrabajador.dirActualNombre"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Número"
                  label-for="dirActualNro"
                >
                  <b-form-input
                    id="dirActualNro"
                    v-model="dataTrabajador.dirActualNro"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Dpto."
                  label-for="dirActualDpto"
                >
                  <b-form-input
                    id="dirActualDpto"
                    v-model="dataTrabajador.dirActualDpto"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Interior"
                  label-for="dirActualInterior"
                >
                  <b-form-input
                    id="dirActualInterior"
                    v-model="dataTrabajador.dirActualInterior"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Manzana"
                  label-for="dirActualManzana"
                >
                  <b-form-input
                    id="dirActualManzana"
                    v-model="dataTrabajador.dirActualManzana"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Lote"
                  label-for="dirActualLote"
                >
                  <b-form-input
                    id="dirActualLote"
                    v-model="dataTrabajador.dirActualLote"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Km"
                  label-for="dirActualKm"
                >
                  <b-form-input
                    id="dirActualKm"
                    v-model="dataTrabajador.dirActualKm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Bloque"
                  label-for="dirActualBloque"
                >
                  <b-form-input
                    id="dirActualBloque"
                    v-model="dataTrabajador.dirActualBloque"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Etapa"
                  label-for="dirActualEtapa"
                >
                  <b-form-input
                    id="dirActualEtapa"
                    v-model="dataTrabajador.dirActualEtapa"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Zona"
                  label-for="dirActualZona"
                >
                  <b-form-input
                    id="dirActualZona"
                    v-model="dataTrabajador.dirActualZona"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  label="Referencia"
                  label-for="dirActualReferencia"
                >
                  <b-form-input
                    id="dirActualReferencia"
                    v-model="dataTrabajador.dirActualReferencia"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <h6
              class="text-info"
            >
              <feather-icon icon="BookmarkIcon" />
              <span class="align-middle ml-25">Otros Datos Adicionales</span>
            </h6>

            <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

            <b-row style="background: aliceblue;">
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center"
              >
                <b-card-text class="mb-0 d-inline">
                  ¿Persona con Discapacidad?
                </b-card-text>
                <div class="d-flex ml-auto">
                  <b-form-checkbox
                    v-model="dataTrabajador.discapacidad"
                    name="check-button"
                    class="mr-0"
                    switch
                    inline
                  />
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="pt-50"
              >
                <b-form-group
                  label="Registro Conadis"
                  label-for="nroConadis"
                >
                  <b-form-input
                    id="nroConadis"
                    v-model="dataTrabajador.nroConadis"
                    :disabled="!dataTrabajador.discapacidad"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row style="background: aliceblue;">
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center"
              >
                <b-card-text class="mb-0 d-inline">
                  ¿Perteneció a las Fuerzas Armadas?
                </b-card-text>
                <div class="d-flex ml-auto">
                  <b-form-checkbox
                    v-model="dataTrabajador.ffaa"
                    name="check-button"
                    class="mr-0"
                    switch
                    inline
                  />
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="pt-50"
              >
                <b-form-group
                  label="Número Constancia"
                  label-for="ffaaConstancia"
                >
                  <b-form-input
                    id="ffaaConstancia"
                    v-model="dataTrabajador.ffaaConstancia"
                    :disabled="!dataTrabajador.ffaa"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="text-center mt-1"
              @click="saveForm"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Guardar</span>
            </b-button>
          </b-form>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="BriefcaseIcon" />
            <span>Información Laboral</span>
          </template>
          <b-form>
            <b-row>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Fecha Ingreso"
                  label-for="fechaIngreso"
                >
                  <flat-pickr
                    v-model="dataTrabajador.fechaIngreso"
                    class="form-control"
                    :config="configDP"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Fecha Cese"
                  label-for="fechaCese"
                >
                  <flat-pickr
                    v-model="dataTrabajador.fechaCese"
                    class="form-control"
                    :config="configDP"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Código Modular"
                  label-for="codigoModular"
                >
                  <b-form-input
                    id="codigoModular"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="3"
              >
                <b-form-group
                  label="Autogenerado EsSalud"
                  label-for="codigoEssalud"
                >
                  <b-form-input
                    id="codigoEssalud"
                    v-model="dataTrabajador.codigoEssalud"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <h6
              class="text-info"
            >
              <feather-icon icon="MonitorIcon" />
              <span class="align-middle ml-25">Régimen Pensionario</span>
            </h6>

            <hr style="border-top: 2px solid #00cfe8;margin-bottom: 1rem !important;margin-top: 0 !important;">

            <b-row>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Tipo Pensión"
                  label-for="pensionTipo"
                >
                  <v-select
                    v-model="dataTrabajador.pensionTipo"
                    label="title"
                    :reduce="m => m.title"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="tiposPensiones"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  label="Nombre Entidad"
                  label-for="pensionNombre"
                >
                  <b-form-input
                    id="pensionNombre"
                    v-model="dataTrabajador.pensionNombre"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Cod. Cuspp"
                  label-for="pensionCodCuspp"
                >
                  <b-form-input
                    id="pensionCodCuspp"
                    v-model="dataTrabajador.pensionCodCuspp"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Fecha Ingreso"
                  label-for="pensionFechaIngreso"
                >
                  <flat-pickr
                    v-model="dataTrabajador.pensionFechaIngreso"
                    class="form-control"
                    :config="configDP"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="2"
              >
                <b-form-group
                  label="Fecha Traspaso"
                  label-for="pensionFechaTraspaso"
                >
                  <flat-pickr
                    v-model="dataTrabajador.pensionFechaTraspaso"
                    class="form-control"
                    :config="configDP"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="UsersIcon" />
            <span>Datos Familiares</span>
          </template>

          <b-card-text>
            Muffin cupcake candy chocolate cake gummi bears fruitcake donut dessert pie. Wafer toffee bonbon dragée. Jujubes cotton candy gummies chupa chups. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Candy cookie sweet roll bear claw sweet roll. Cake tiramisu cotton candy gingerbread cheesecake toffee cake. Cookie liquorice dessert candy canes jelly.
          </b-card-text>
          <b-card-text>
            Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread jelly beans chocolate bar oat cake wafer. Chocolate bar danish icing sweet apple pie jelly-o carrot cake cookie cake.
          </b-card-text>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>Anexos</span>
          </template>

          <b-card-text>
            Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee apple pie chocolate bar biscuit tart croissant. Lemon drops danish cookie. Oat cake macaroon icing tart lollipop cookie sweet bear claw.
          </b-card-text>
          <b-card-text>
            Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies dragée. Chocolate bar liquorice cheesecake cookie chupa chups marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream powder tootsie roll cake.
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card-body>
  </b-card>
</template>

<script>
import store from '@/store'
import {
  BAvatar, BButton, BCol, BForm, BFormGroup, BFormInput, BFormCheckbox, BInputGroup, BMedia, BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCardSubTitle, BRow, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  directives: {
    Ripple,
  },
  components: {
    BAvatar,
    BButton,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BMedia,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BRow,
    flatPickr,
    vSelect,
    BTabs,
    BTab,
  },
  props: {
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
    }
  },
  setup(props) {
    console.log(props.dataEdit)
    const isBusy = ref(false)
    const tipoDocSel = ref(null)
    const tiposDocument = ref([])
    const estadosCiviles = ref([])
    const nroDoc = ref('')
    const dataTrabajador = ref(props.dataEdit)
    const sexos = ref([{ title: 'MASCULINO' }, { title: 'FEMENINO' }])
    const idiomas = ref([{ title: 'CASTELLANO' }, { title: 'INGLES' }])
    const tiposSangre = ref([{ title: 'A+' }, { title: 'O+' }, { title: 'B+' }, { title: 'AB+' }, { title: 'A-' }, { title: 'O-' }, { title: 'B-' }, { title: 'AB-' }])
    const paises = ref([{ title: 'PERÚ' }])
    const tiposPensiones = ref([{ title: 'ONP' }, { title: 'AFP' }])
    const paisNacSel = ref('PERÚ')
    const dptosNac = ref([])
    const provsNac = ref([])
    const distsNac = ref([])
    const dptosRes = ref([])
    const provsRes = ref([])
    const distsRes = ref([])
    const dptoNacSel = ref(null)
    const provNacSel = ref(null)
    const distNacSel = ref(null)
    const dptoResSel = ref(null)
    const provResSel = ref(null)
    const distResSel = ref(null)

    const configDP = ref(
      {
        enableTime: false,
        dateFormat: 'd/m/Y',
        altInput: true,
        altFormat: 'd/m/Y',
      },
    )

    const getTiposDocumento = async () => {
      await store.dispatch('catalogo/TIPO_DOCUMENTO_FIND_ALL')
        .then(response => {
          if (response) {
            tiposDocument.value = response
            tipoDocSel.value = tiposDocument.value.find(item => item.idTipoDocumento === dataTrabajador.value.tipoDocumento.idTipoDocumento)
          }
        })
    }

    const getEstadosCiviles = async () => {
      await store.dispatch('gsescalafon/TRABAJADOR_GET_ESTADO_CIVIL')
        .then(response => {
          if (response) {
            estadosCiviles.value = response
          }
        })
    }

    const getDptos = async type => {
      await store.dispatch('gsescalafon/UBIGEO_GET_DPTOS')
        .then(response => {
          if (response) {
            if (type === 'NAC') {
              dptosNac.value = response
            } else if (type === 'RES') {
              dptosRes.value = response
            }
          }
        })
    }

    const getProvs = async (type, codDpto) => {
      await store.dispatch('gsescalafon/UBIGEO_GET_PROVS', { codDpto })
        .then(response => {
          if (response) {
            if (type === 'NAC') {
              provsNac.value = []
              provNacSel.value = null
              provsNac.value = response
            } else if (type === 'RES') {
              provsRes.value = []
              provResSel.value = null
              provsRes.value = response
            }
          }
        })
    }

    const getDists = async (type, codDpto, codProv) => {
      console.log('la ptm ', codDpto, codProv)
      await store.dispatch('gsescalafon/UBIGEO_GET_DISTS', { codDpto, codProv })
        .then(response => {
          if (response) {
            if (type === 'NAC') {
              distsNac.value = response
              distNacSel.value = null
            } else if (type === 'RES') {
              distsRes.value = response
              distResSel.value = null
            }
          }
        })
    }

    const searchReniec = async () => {
      console.log('estamoshh')
    }

    const saveForm = async () => {
      // Validar datos antes
      const service = 'gsescalafon/TRABAJADOR_CREATE'
      /* if (this.typeAction === 'edit') {
        this.formData.idFichaReclutamiento = this.dataEdit.idFichaReclutamiento
        service = 'gsescalafon/TRABAJADOR_UPDATE'
      } */
      dataTrabajador.value.discapacidad = false
      dataTrabajador.value.lugarNacimiento = distNacSel.value
      dataTrabajador.value.tipoDocumento = tipoDocSel.value
      dataTrabajador.value.estado = true
      dataTrabajador.value.rutaCv = 'string'
      dataTrabajador.value.fextensionCv = 'string'
      dataTrabajador.value.fileTipoCv = 'string'
      dataTrabajador.value.nroConadis = 'string'

      await store.dispatch(service, dataTrabajador.value)
        .then(response => {
          console.log('oh yeah', response)
        })
        .catch(error => {
          console.log('errror : ', error)
        })
    }

    getTiposDocumento()
    getEstadosCiviles()
    getDptos('NAC')
    getDptos('RES')

    return {
      isBusy,
      tipoDocSel,
      tiposDocument,
      estadosCiviles,
      tiposSangre,
      paises,
      paisNacSel,
      nroDoc,
      dataTrabajador,
      searchReniec,
      sexos,
      idiomas,
      tiposPensiones,
      configDP,
      dptosNac,
      provsNac,
      distsNac,
      dptosRes,
      provsRes,
      distsRes,
      getProvs,
      getDists,
      dptoNacSel,
      provNacSel,
      distNacSel,
      dptoResSel,
      provResSel,
      distResSel,
      saveForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.header-df{
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}
.nav-tabs{
  background: #f6f6f6 !important;
}
.form-group {
  margin-bottom: 0.5rem !important;
}
</style>
