<template>
  <div>
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >

        <!-- payment tab -->
        <b-tab active>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN I</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Filiación e identificación personal
              </p>
            </div>
          </template>
          <seccion-i
            v-if="isLoad"
            :data-edit="dataEdit"
          />
        </b-tab>
        <!--/ payment tab -->

        <!-- delivery tab -->
        <b-tab>

          <!-- title -->
          <template #title>
            <font-awesome-icon
              icon="user-graduate"
              size="1x"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN II</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Estudios adquiridos
              </p>
            </div>
          </template>

        </b-tab>
        <!--/ delivery tab -->

        <!-- cancellation and return -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserCheckIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN III</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Ingreso o reingreso
              </p>
            </div>
          </template>
        </b-tab>
        <!--/ cancellation and return -->

        <!-- my order -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="TrendingUpIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN IV</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Trayectoria laboral
              </p>
            </div>
          </template>
        </b-tab>
        <!--/ my order -->

        <!-- Product & Services -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="TagIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN V</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Asignaciones e incentivos
              </p>
            </div>
          </template>
        </b-tab>
        <!-- Product & Services -->

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="LogOutIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN VI</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Retiro y régimen pensionario
              </p>
            </div>
          </template>
        </b-tab>

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="AwardIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN VII</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Premios y estímulos
              </p>
            </div>
          </template>
        </b-tab>

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="ThumbsDownIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN VIII</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Sanciones
              </p>
            </div>
          </template>
        </b-tab>

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="BriefcaseIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN IX</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Licencias y vacaciones
              </p>
            </div>
          </template>
        </b-tab>

        <b-tab>
          <!-- title -->
          <template #title>
            <feather-icon
              icon="ColumnsIcon"
              size="18"
              class="mr-1"
            />
            <div class="chat-info flex-grow-1">
              <span class="font-weight-bold">SECCIÓN X</span>
              <p
                class="card-text text-truncate mb-0"
                style="font-size: 0.8rem;"
              >
                Otros
              </p>
            </div>
          </template>
        </b-tab>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import store from '@/store'
import { showToast, showError } from '@/helpers'
import SeccionI from './SeccionI.vue'

export default {
  components: {
    BTabs,
    BTab,
    SeccionI,
  },
  data() {
    return {
      faqData: {},
      dataEdit: {},
      isLoad: false,
    }
  },
  created() {
    console.log('el valor seria: ', this.$route.params.id)
    this.$http.get('/faq/data').then(res => { this.faqData = res.data })
  },
  mounted() {
    this.getDatos()
  },
  methods: {
    async getDatos() {
      this.isLoad = false
      await store
        .dispatch('rrhh/TRABAJADOR_FIND_BY_ID', {
          id: this.$route.params.id,
        })
        .then(response => {
          this.dataEdit = response.result
          this.isLoad = true
        })
        .catch(error => {
          this.$bvToast.toast(error, {
            title: 'Ha ocurrido un error',
            variant: 'danger',
            solid: true,
          })
        })
    },
    showToast,
    showError,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-trabajador-detalle.scss';
</style>
